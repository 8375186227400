<template>
  <main class="home container">
    <meta-home :job-count="jobCount" />
    <div class="home__side_section">
      <home-side-menu event-source="home.category" />
    </div>
    <div class="home__main">
      <div
        v-if="announcement"
        class="announcement"
        :class="`${announcement.style === 'Warning' ? 'announcement-warning' : 'announcement-reminder'}`"
      >
        <div class="announcement__container">
          <svg-announcement class="announcement__megaphone-icon" />
          <div class="announcement__body">
            <p class="announcement__title">{{ announcementTitle }}</p>
            <p class="announcement__content">
              {{ announcementMessage }}
              <a
                v-if="announcement.link_url"
                :href="announcement.link_url"
                :target="announcement.link_target"
                class="announcement__link"
                >{{ announcementTextLink }}</a
              >
            </p>
          </div>
          <svg-close
            v-if="announcement.show_close_button"
            class="announcement__close-icon svg-fill"
            @click="dismissAnnouncement"
          />
        </div>
      </div>
      <home-banner v-if="banners.length" class="home__banners" :items="banners" :session-id="session.banners" />
      <home-job-cate-menu event-source="home.category" />
      <div v-if="featuredLists.length" class="home__featured-lists">
        <template v-for="list in featuredLists">
          <featured-list
            :id="list._id"
            :key="`HomeFeaturedList-${list._id}`"
            :class="featuredJobsWithAds(list) ? 'featured-jobs-with-ads' : ''"
            source="home.feature_list"
            :jobs="list.jobs"
            :session-id="session.featuredLists[list._id]"
            :filter-query-object="getFilterQueryObjectFromData(list.data)"
            :title-locales="list.name"
          />
        </template>
      </div>
      <rec-list
        v-if="recommendedList"
        class="home__related-lists"
        source="home.related_job"
        :jobs="recommendedList.jobs"
        :session-id="session.recommendedList"
      />
      <horizontal-featured-company-list
        v-if="featuredCompanyList.length"
        class="home__feature-company-lists"
        source="homepage"
        :companies="featuredCompanyList"
        :title="featuredCompanyListTitle"
      />
    </div>
  </main>
</template>

<script>
import BiddingMixin from '../mixins/bidding'
import { createSessionId } from '../libs/tracking/utils/session'
import FeaturedList from '../components/list/featuredList'
import { GET_HOMEPAGE_FULL_QUERY } from '../api/octo/graphql/queries'
import { getFeaturedCompanyListTitle } from '@/libs/featuredCompanyList'
import GraphqlFormMixin from '../mixins/form/graphql'
import HomeBanner from '../components/banner/HomeBanner'
import HomeJobCateMenu from '../components/home/homeJobCateMenu'
import HomeMetaData from '../components/home/homeMetaData'
import HomeSideMenu from '../components/home/homeSideMenu'
import HorizontalFeaturedCompanyList from '@/components/list/horizontalFeaturedCompanyList'
import { isEnvProduction } from '../libs/env'
import PlatformMixin from '@/mixins/platform'
import RecommendedList from '../components/list/recommendedList'
import SvgAnnouncement from '../assets/svg/megaphone-line.svg'
import SvgClose from '../assets/svg/close.svg'
import { transformSearchQueryToFilterQueryObject } from '../libs/search/filter'

export default {
  asyncData({ payload = {} }) {
    // Restore banners and featured lists if available
    const { banners, featuredLists, jobCount, featuredCompanyList, featuredCompanyListLocale } = payload
    if (Array.isArray(banners) && Array.isArray(featuredLists) && Array.isArray(featuredCompanyList)) {
      return { banners, featuredCompanyList, featuredCompanyListLocale, featuredLists, jobCount }
    }
  },
  components: {
    'featured-list': FeaturedList,
    'home-banner': HomeBanner,
    'home-job-cate-menu': HomeJobCateMenu,
    'home-side-menu': HomeSideMenu,
    'horizontal-featured-company-list': HorizontalFeaturedCompanyList,
    'meta-home': HomeMetaData,
    'rec-list': RecommendedList,
    'svg-announcement': SvgAnnouncement,
    'svg-close': SvgClose,
  },
  computed: {
    announcementMessage() {
      if (this.announcement?.message) {
        return this.currentLocale.code === 'zh-hant' ? this.announcement.message.zh : this.announcement.message.en
      }
      return ''
    },
    announcementTextLink() {
      if (this.announcement?.text_link?.zh) {
        return this.currentLocale.code === 'zh-hant' ? this.announcement.text_link.zh : this.announcement.text_link.en
      }
      return ''
    },
    announcementTitle() {
      if (this.announcement?.title?.zh) {
        return this.currentLocale.code === 'zh-hant' ? this.announcement.title?.zh : this.announcement.title?.en
      }
      return ''
    },
    featuredCompanyListTitle() {
      return getFeaturedCompanyListTitle(this.featuredCompanyListLocale, this.$i18n.locale)
    },
  },
  created() {
    // On client side
    if (process.client) {
      // Create session IDs
      this.createSessionIDsIfEmpty()

      // Fetch homepage data once token ready
      this.$tokenReady(this.getHomepage)
    }
  },
  data() {
    return {
      announcement: null,
      banners: [],
      featuredCompanyList: [],
      featuredCompanyListLocale: [],
      featuredLists: [],
      /**
       * Graphql queries
       */
      gqlQueries: {
        homePageQuery: GET_HOMEPAGE_FULL_QUERY,
      },
      /**
       * Avoid to load twice
       */
      isLoading: false,
      jobCount: null,
      recommendedList: null,
      session: {
        banners: '',
        featuredLists: {},
        recommendedList: '',
      },
    }
  },
  head() {
    const url = `${process.env.SEEKER_WEB__BASE_URL}/`
    return {
      /**
       * Add *Sitelinks search box* to Google search result,
       * ref: https://developers.google.com/search/docs/data-types/sitelinks-searchbox
       */
      script: [
        {
          hid: 'website',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            potentialAction: {
              '@type': 'SearchAction',
              'query-input': 'required name=search_term_string',
              target: `${url}search/?q={search_term_string}`,
            },
            url,
          },
          type: 'application/ld+json',
        },
      ],
    }
  },
  methods: {
    /**
     * Create IDs for session(s) w/o ID yet
     */
    createSessionIDsIfEmpty() {
      if (!this.session.recommendedList) {
        this.session.recommendedList = createSessionId()
      }
      if (!this.session.banners) {
        this.session.banners = createSessionId()
      }
      this.featuredLists.forEach((list) => {
        if (!this.session.featuredLists[list._id]) {
          this.session.featuredLists[list._id] = createSessionId()
        }
      })
    },

    dismissAnnouncement() {
      localStorage.setItem(this.announcement.id, 'SEEN')
      this.announcement = null
    },

    featuredJobsWithAds(list) {
      return list.name.some((locale) => locale.value === 'Recommended jobs')
    },

    async getAnnouncement() {
      const path = 'announcement/announcement.json'
      const url = isEnvProduction() ? `https://moovup.com/${path}` : `https://seeker-web-staging.moovup.com/${path}`

      try {
        const response = await fetch(url)
        if (!response.ok) return
        const json = await response.json()

        // is_active is the field that determines whether the announcement will be shown or not
        if (json?.is_active && localStorage.getItem(json.id) !== 'SEEN') {
          this.announcement = json
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching announcement:', error)
      }
    },

    getFilterQueryObjectFromData(data) {
      if (data && data.search_query) {
        return transformSearchQueryToFilterQueryObject(data.search_query)
      }
    },

    /**
     * Handle fetching homepage data from backend
     * and updating existing data from server-side generation
     * @returns {Promise<void>}
     */
    async getHomepage() {
      if (!this.isLoading) {
        this.isLoading = true
        const res = await this.submitGql('homePageQuery', true)
        await this.loadAdsForHomepageRecommendedJobs()
        await this.getAnnouncement()
        this.isLoading = false
        if (res && res.data && res.data.homepage) {
          const homepage = res.data.homepage
          // Replace featured lists
          // w/ fetched non-empty lists
          if (Array.isArray(homepage.job_featured_lists)) {
            this.featuredLists = homepage.job_featured_lists.filter(
              (list) => list && list._id && Array.isArray(list.jobs) && list.jobs.length,
            )
          }
          // Combine 2 ads and 4 jobs in recommended job list
          if (this.featuredLists.length && this.biddingAdJobs.length) {
            this.featuredLists.forEach((list) => {
              if (list.name.some((locale) => locale.value === 'Recommended jobs')) {
                const jobList = list.jobs.slice(0, 4)
                const jobAdList = this.biddingAdJobs.slice(0, 2)
                const position = this.isMobile ? [1, 3] : [3, 4]
                const jobListWithAds = Array(6).fill(null)
                let i = 0
                while (i < 6) {
                  if (position.includes(i)) {
                    jobListWithAds[i] = jobAdList.shift()
                  } else {
                    jobListWithAds[i] = jobList.shift()
                  }
                  i++
                }
                list.jobs = jobListWithAds
              }
            })
          }

          // Set recommended list if not empty
          if (
            homepage.job_recommended_list &&
            Array.isArray(homepage.job_recommended_list.jobs) &&
            homepage.job_recommended_list.jobs.length
          ) {
            this.recommendedList = homepage.job_recommended_list
          }

          if (
            homepage.featured_companies &&
            Array.isArray(homepage.featured_companies.companies) &&
            homepage.featured_companies.companies.length
          ) {
            this.featuredCompanyList = homepage.featured_companies.companies
            this.featuredCompanyListLocale = homepage.featured_companies.name
          }

          // Set banners list
          // if not available yet and not empty
          if (Array.isArray(homepage.home_banners) && homepage.home_banners.length) {
            this.banners = homepage.home_banners
          }
        }

        // Create session IDs for added featured lists
        this.createSessionIDsIfEmpty()
      }
    },
  },
  mixins: [GraphqlFormMixin, BiddingMixin, PlatformMixin],
  name: 'HomePage',
}
</script>

<style lang="postcss" scoped>
.home {
  @apply flex flex-row w-full;
}
.announcement {
  @apply mt-11 mx-2 p-3 rounded-md;
}
.announcement-warning {
  background: #ffe4d5;
}
.announcement-reminder {
  background: #ebebff;
}
.announcement-reminder >>> .announcement__megaphone-icon path {
  stroke: #6366f1;
}
.announcement__container {
  @apply flex items-start w-full;
}
.announcement__close-icon {
  @apply w-5 cursor-pointer text-grey-400;
  flex: 0 0 20px;
}
.announcement__megaphone-icon {
  @apply w-6 mr-2;
  flex: 0 0 24px;
}
.announcement__body {
  @apply flex-grow mr-3;
}
.announcement__title {
  @apply font-bold text-grey-700;
}
.announcement__content {
  @apply text-grey-600;
}
.announcement__link {
  @apply font-bold underline text-sm text-grey-700 whitespace-no-wrap;
}
.home__banners {
  @apply mb-7;
}
.home__featured-lists {
  @apply mt-2 mb-3;
}
.home__main {
  @apply min-w-0 flex-1 px-2 mx-auto;
}

.home__related-lists {
  @apply mb-11;
}

@screen mobile {
  .announcement {
    @apply mt-4;
  }

  .featured-jobs-with-ads >>> .list__jobs {
    @apply flex flex-wrap flex-col;
    height: 326px;
  }
  .featured-jobs-with-ads >>> .list__job:not(:first-child) {
    @apply pl-2;
  }
  .home >>> .overlay .overlay__bg {
    @apply absolute inset-0 z-0 bg-black-plain;
    opacity: 0.35;
  }
  .home__banners {
    @apply mt-4;
  }
  .home__side_section {
    @apply hidden;
  }
  .home__related-lists {
    @apply mb-2;
  }
}

@screen desktop {
  .home {
    margin-bottom: -46px;
  }
  .home__main {
    @apply mb-8;

    & .home-job-cate-menu {
      @apply hidden;
    }
  }
  .home__banners,
  .home__side_section {
    @apply mt-11;
  }
}

/* Hard code to media query to handle low resolution device */
/* Special handling for home page only. */
@media (min-width: 960px) and (max-width: 1199px) {
  .home {
    max-width: 960px;

    &::v-deep .banner__list-container {
      max-width: 590px;
    }
  }
  .home__featured-lists,
  .home__related-lists {
    &::v-deep .list__job {
      width: 50%;
    }
  }
  .home__feature-company-lists {
    &::v-deep .list__job {
      @apply w-1/3;
    }
  }
}

/* Hard code to media query to handle wide screen */
/* Special handling for home page only. */
@media (min-width: 1200px) {
  .home {
    max-width: 1200px;
  }
  .home__featured-lists,
  .home__related-lists {
    &::v-deep .list__job {
      width: 100/3%;
    }
  }
}
</style>
